import revive_payload_client_YDRifL3xJW from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.55_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pO42bQzGfF from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.55_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hu8xu6ALeu from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.55_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_i8TD40IaQX from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.55_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_d2ZHwPX2ua from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.55_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_zl4XCo3vPF from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.55_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_OyVjigGUub from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.55_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_7F9lReALU2 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.55_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/vsts/work/1/s/.nuxt/pwa-icons-plugin.ts";
import pwa_client_R3Tu761Bq6 from "/home/vsts/work/1/s/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_rollup@4.24.0_vite@5.4.8_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_CAoycyGTOK from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@8.0.2_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _1_application_insights_client_2isRgr4PXf from "/home/vsts/work/1/s/plugins/1.application-insights.client.ts";
import _2_auth_42b9fB0DY4 from "/home/vsts/work/1/s/plugins/2.auth.ts";
import _3_axios_5VOloTgdvi from "/home/vsts/work/1/s/plugins/3.axios.ts";
import error_handler_kEP5FliEXj from "/home/vsts/work/1/s/plugins/error-handler.ts";
import nuxt_link_import_yzIAaKi1HN from "/home/vsts/work/1/s/plugins/nuxt-link-import.ts";
import pwa_client_PLDhtd2phb from "/home/vsts/work/1/s/plugins/pwa.client.ts";
import router_watch_LdArCFqd7Z from "/home/vsts/work/1/s/plugins/router-watch.ts";
import sentry_logging_o4SBtmowK2 from "/home/vsts/work/1/s/plugins/sentry-logging.ts";
import vue_pdf_3byIq144W2 from "/home/vsts/work/1/s/plugins/vue-pdf.ts";
import vue_router_append_cjk9JpQRKU from "/home/vsts/work/1/s/plugins/vue-router-append.ts";
import vue_signature_pad_7H3GYOYmnY from "/home/vsts/work/1/s/plugins/vue-signature-pad.ts";
export default [
  revive_payload_client_YDRifL3xJW,
  unhead_pO42bQzGfF,
  router_hu8xu6ALeu,
  payload_client_i8TD40IaQX,
  navigation_repaint_client_d2ZHwPX2ua,
  check_outdated_build_client_zl4XCo3vPF,
  chunk_reload_client_OyVjigGUub,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7F9lReALU2,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_R3Tu761Bq6,
  i18n_CAoycyGTOK,
  _1_application_insights_client_2isRgr4PXf,
  _2_auth_42b9fB0DY4,
  _3_axios_5VOloTgdvi,
  error_handler_kEP5FliEXj,
  nuxt_link_import_yzIAaKi1HN,
  pwa_client_PLDhtd2phb,
  router_watch_LdArCFqd7Z,
  sentry_logging_o4SBtmowK2,
  vue_pdf_3byIq144W2,
  vue_router_append_cjk9JpQRKU,
  vue_signature_pad_7H3GYOYmnY
]